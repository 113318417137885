import React, { useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";
import service from "../../utils/service";

const CustomDropDown = ({ caseId, onAssigneeSelect, data, type, getTypeAndAssignee, Text, InformationPage, marginValue }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [assigneeClicked, setAssigneeClicked] = useState(false);
  const [assigneeLoading, setAssigneeLoading] = useState(false);
  const [caseAssigneeData, setCaseAssigneeData] = useState([]);
  const [caseAssigneeCode, setCaseAssigneeCode] = useState({
    code: undefined,
    msg: "",
  });
  const caseStatus = _.get(data, "case_status", null);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const trigger_getAssignee_API = async () => {
    try {
      let API_URL = `v1/cases/${caseId}/${type}`;
      let res = await service("get", API_URL);
      const statusCode = res?.status ?? 0;
      const message = res?.data?.message ?? "";
      setCaseAssigneeCode({ code: statusCode, msg: message });
      let data = _.get(res, "data", "");
      if (_.isArray(data)) {
        setAssigneeLoading(true);
        setCaseAssigneeData(data);
      }
    } catch (error) {
      setCaseAssigneeCode({
        code: error.response?.status ?? 500,
        msg: error.message ?? "An error occurred",
      });
      setAssigneeLoading(false);
    }
  };

  useEffect(() => {
    if (assigneeClicked) {
      trigger_getAssignee_API();
    }
  }, [assigneeClicked]);

  const getAssigneeDropdown = () => {
    if (caseAssigneeCode.code === 403) {
      return (
        <DropdownMenu className="dropdown-menu-hover-link custom-width me-5">
          <div className="p-2">{caseAssigneeCode.msg}</div>
        </DropdownMenu>
      );
    }
    return (
      <DropdownMenu className="dropdown-align-right">
        {assigneeLoading ? (
          <>
            {caseAssigneeData.length > 0 ? (
              <div>
                <div className="p-2">{Text}</div>
                {caseAssigneeData.map((assignee) => (
                  <div
                    key={assignee._id}
                    className="dropdown-item d-flex p-2 cursor-p custom-width"
                    onClick={() => {
                      if (type === "case_type") {
                        getTypeAndAssignee(assignee._id, assignee.ticket_workflow_id);
                      } else {
                        onAssigneeSelect(assignee._id);
                      }
                    }}
                  >
                    {type === "case_assignee" && <img src={`/cdn/assets/icons/user-profile.svg`} className="me-2" style={{ height: "20px" }} />}
                    <span className={`pr-2 fs-12 ${type === "case_assignee" ? "text-primary" : assignee.name}`}>{assignee.name}</span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-2 fs-12 custom-width">
                {type === "case_assignee" ? "No Assignee Found" : "No CaseType Found"}
              </div>
            )}
          </>
        ) : (
          <Skeleton count={5} />
        )}
      </DropdownMenu>
    );
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle className={`btn-${caseStatus&&caseStatus.toLowerCase()} fs-12 text-dark fw-600  d-flex align-items-center`} onClick={() => setAssigneeClicked(true)}>
        <div>
          {type === "case_assignee" ? (
            <span>{_.get(data, "case_assignee", "")}</span>
          ) : (
            <span>{_.get(data, "case_type", "")}</span>
          )}
        </div>
        <div className="ms-1">
          <i className="edit" />
        </div>
      </DropdownToggle>
      {getAssigneeDropdown()}
    </Dropdown>
  );
};

export default CustomDropDown;

